@mixin breakpoint() {
  @media only screen and (min-width: 768px) {
     @content;
  }
}

@mixin bounceTransition() {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1.4, 1, 1);
}
