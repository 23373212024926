@import './mixins.scss';

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

body {
  background-color: #222;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

h1 {
  font-size: 1.75rem;

  @include breakpoint() {
    font-size: 2rem;
  }
}

h2 {
  font-size: 1.25rem;

  @include breakpoint() {
    font-size: 1.5rem;
  }
}

html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
